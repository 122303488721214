// eslint-disable-next-line import/no-import-module-exports
import React from 'react';
// eslint-disable-next-line import/no-import-module-exports
import { createRoot } from 'react-dom/client';

const App = React.lazy(() => import('./App'));

const container = document.getElementById('root');
if (container) {
  createRoot(container).render(<App />);
}

if (module.hot) {
  module.hot.accept();
}
